@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.brand-shadow {
    box-shadow:
        0px 0px 0px 0px rgba(81, 168, 255, 0.1),
        0px 8px 19px 0px rgba(81, 168, 255, 0.1),
        0px 34px 34px 0px rgba(81, 168, 255, 0.09),
        0px 76px 45px 0px rgba(81, 168, 255, 0.05),
        0px 135px 54px 0px rgba(81, 168, 255, 0.01),
        0px 210px 59px 0px rgba(81, 168, 255, 0);
}
